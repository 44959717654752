.is-blue {
    color: #0841b0 !important;
}

.vl {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 3rem;
    margin-right: 3rem;
    border-left: 1px solid #00ae41;
    height: 180px;
}
